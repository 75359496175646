$body-bg: #F5F5F5 ;
$navbar-default-bg: #FFF;
$navbar-height: 63px;

$brand-primary:         #F44336;
$brand-info:            #90A4AE;

$font-family-sans-serif:  Roboto, sans-serif;

$btn-default-color:              #fff;
$btn-default-bg:                 $brand-primary;
$btn-default-border:             $brand-primary;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $brand-primary;

$btn-info-color:                 #fff;
$btn-info-border:                $brand-info;

$border-radius-base:             2px;

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.btn {
  /*-webkit-transition:all .3s ease-in-out;*/
  /*-moz-transition:all .3s ease-in-out;*/
  /*-webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);*/
  /*transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);*/
  &:hover {
    -webkit-transform: scale(1.01, 1.01);
    transform: scale(1.01, 1.01);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    &:after {
      opacity: 1;
    }
  }

}

.splash-body {
  background-image: url(../img/enterprise-efficiency.jpg);
}

.margin-right-10 {
  margin-right: 10px;
}
.margin-right-30 {
  margin-left: 30px;
}
//Navbar Fuss

.navbar-brand {
  padding-top: 10px;
}

.navbar.navbar-default {
  border: none;
}

.dropdown-menu {
  > li {
    > a {
      -webkit-transition: all .5s ease-in-out;
      -moz-transition: all .5s ease-in-out;
    }
  }
}

.navbar-static-top {
  margin-bottom: 0px;
}

.navbar-collapse {
  padding-left: 0px;
}

.quick-links {
  margin: {
    top: 10px;
    right: 11px;
  }
  font-size: 12px;
}

.secondary-navbar {
  .navbar-nav {
    > li {
      > a {
        -webkit-transition:all .5s ease-in-out;
        -moz-transition:all .5s ease-in-out;
        padding: {
          top: 13px;
          bottom: 14px;
        }
      }
    }
  }
  .navbar.navbar-default {
      background-color: $body-bg;
      border-color: $body-bg;
      border-radius: 1px;
      height: 30px;
     a {
      color: #424242;
       -webkit-transition:all .5s ease-in-out;
       -moz-transition:all .5s ease-in-out;
      &:hover {
        color: #424242;
        background-color: #fff;
      }
      &:active {
        color: #424242;
        background-color: #fff;
      }
    }
  }
}

.dropdown-menu.quick-links-dropdown {
  background-color: $brand-primary;
  margin-top: -2px;
  margin-right: 11px;
  border: none;
  >li {
    >a {
      color: #fff !important;
      font-weight: 300 !important;
      font-size: 12px;
      &:hover {
        color: #fff !important;
        background-color: darken($brand-primary, 6.5%) !important;
      }
      &:active {
        color: #424242 !important;
        background-color: #fff !important;
      }
    }
  }
}

.dropdown-menu.logged-in-dropdown {
  border: none;
}

//End of Navbar Fuss

//Dashboard Wells

.well {
  overflow: hidden;
  border: {
    radius: 2px;
    color: $body-bg;
    }
  box-shadow: none;
  background-color: #fff;
  p {
    font: {
      size: 14px;
    }
  }
  h1 {
     font: {
       size: 20px;
       weight: 300 !important;
     }
     margin: {
       top: 10px;
       left: 10px;
     }
  }
}

.well.mission-critical {
  background-color: $brand-primary;
  color: $btn-default-color;

}

.well.components-needed{
  background-color: $brand-info;
  color: $btn-info-color;

}

.well.upload-list {
  background-color: $body-bg;
  //color: $btn-info-color;
}


.top-right-button {
  position: relative;
  top: -40px;
  text-align: end;
}

.dashboard-container {
  padding-left: 30px;
  padding-right: 30px;
}

// form

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-80 {
  margin-top: 80px;
}

.well {
  .form-group {
    margin: {
      left: 15px;
      top: 15px;
      right: 15px;
    }
  }
}

.table{
  tbody {
    tr {
      th {
        border-top: none;
        border-bottom: 1px solid $brand-info;
      }
      td{
        vertical-align: middle;
        border-top: none;
        border-bottom: 1px solid $brand-info;
      }
    }
  }
}

// Update permissions page

.current-user-status {
  padding-left: 5px;
  font-size: 16px !important;
}

.important-info {
  color: $brand-primary;
}

// invite page styling


  .onoffswitch {
    position: relative; width: 40px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    display: none;
  }
  .onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 12px; padding: 0; line-height: 12px;
    border: 0px solid #FFFFFF; border-radius: 20px;
    background-color: #9E9E9E;
  }
  .onoffswitch-label:before {
    content: "";
    display: block; width: 20px; margin: -4px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 24px;
    border-radius: 20px;
    box-shadow: 0 6px 12px 0px #757575;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: #A82B2B;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    border-color: #A82B2B;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px;
    background-color: #F44336;
    box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
  }

// client tabs

.nav-tabs > li > a {
  -webkit-transition:all .5s ease-in-out;
  -moz-transition:all .5s ease-in-out;
}



// warnings and alerts

.alert-danger {
  background-color: #fff;
  border-color: $brand-primary;
  border-width: 3px;
  color: $brand-primary;
  ul {
    list-style-type: none;

    li {
      -webkit-padding-start: 0;
      -webkit-margin-before: 0;
    }
  }
}

.has-error {
  .control-label {
    color: $brand-primary;
  }
  .help-block {
    color: $brand-primary;
  }
  .form-control {
    border-color: $brand-primary;
    border-width: 3px;
  }
}
.number-input {
  width: 60px;
  display: inline;
  margin-right: 10px;
  margin-left: 10px;
}

.alert-success {
  background-color: #fff;
  border-color: $brand-info;
  border-width: 3px;
  color: $brand-info;
}

.pagination {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
}

.well .search-box .form-group {
  margin-top: 0px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.text-black {
  color: #000000;
  &:hover {
    cursor: pointer ;
  }
}

.team {
  background-color: $brand-primary;
  &:Hover {
    //background-color: #A82B2B;
    -webkit-transition:all .5s ease-in-out;
    -moz-transition:all .5s ease-in-out;
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  height: 100px;
  a {
    color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
      text-decoration: none;
    }
  }
  margin: 10px;
  padding: 0;
}

.team-link {

  position: relative;
  top: 40px;
}

.red-text {
  color: $brand-primary;
}

.margin-left-4 {
  margin-left: 4px;
}

.margin-left-10 {
  margin-left: 10px;
}



  //********* MEDIA QUERIES *********\\


  // LG screen size

  @media(min-width:1200px) {

  }
  // MD screen size (large tablet)
  @media(min-width:992px) and (max-width:1199px) {

  }
  // SM screen size (small tablet)
  @media(min-width:768px) and (max-width:991px) {

  }
  // XS screen size (mobile)
  @media(max-width:767px){

  }

//the component is not in the system

.red-row:before {
    content: "\f00d";
    font-family: FontAwesome;
    color: $brand-primary;
    margin-right: 5px;
  }


//components exist in system but not in stock in warehouse - purchase order or transfer required
.orange-row:before {
  content: "\f071";
  font-family: FontAwesome;
  color: orange;
  margin-right: 5px;
}

//components and stock exist, but there is not enough in warehouse - purchase order or transfer required
.yellow-row:before {
  content: "\f071";
  font-family: FontAwesome;
  color: yellow;
  margin-right: 5px;
}

// there is enough stock and is in warehouse
.green-row:before {
  content: "\f00c";
  font-family: FontAwesome;
  color: limegreen;
  margin-right: 5px;
}


.print-text {
    display: none !important;
}


@media print
{
  .print-text {
    display: inline !important;
  }

  .no-print, .no-print *
  {
    display: none !important;
  }

  a:after {
    content: " [" attr(href) "] ";
    text-decoration: none;
    display: inline;
  }

  td {
    font-size: 11px;
  }

  th {
    font-size: 11px;
    font-weight: bold;
  }

  .btn-group {
    margin-top: 5px;
  }

  .btn-primary {

      border: none !important;
      text-decoration: underline !important;
  }

  .margin-top-80 {
    margin-top: 40px;
  }

  .margin-top-20 {
    margin-top: 25px;
  }

  .printed-sort-label {
    font-size: 14px;
    font-weight: bold;
  }

  btn {
    font-size: 11px !important;
  }

  .print-spacer {
    margin-top: 30px;
  }

}

.nopadding {
  padding: 0px !important;
}

.width-30 {
  width: 130px !important;
}

.nomargin {
  margin: 0px !important;
}

.box {
  color: #FFFFFF;
  height: 160px;
  li {
    font-size: 12px !important;
  }
  h1 {
    font: {
      size: 18px;
      weight: 300 !important;
    }
  }
  i {

  }
  &.light-grey-box {
    background-color: #90A4AE !important;
    padding: 55px 25px 25px 25px;
  }
  &.mid-grey-box {
    background-color: #697C85;
    padding: 25px;
  }
  &.dark-grey-box {
    background-color: #58656B;
    padding: 55px 25px 25px 25px;
  }
}

.arrow {
  margin-top: 22px;
}

.padding-right-0 {
  padding-right: 0px !important;
  margin-left: -10px;
}

.conflicts-list {
  ul {
    list-style: none;
    padding-left: 0px;
  }
  .panel {
    border: none;
  }
}

.white-link {
  color: #fff;
  text-decoration: underline;
}

.navbar-team-name {
  font-size: 22px;
  font-weight: 100;
  margin-top: 15px;
  margin-right: 6px;
  margin-bottom: 15px;
}

.width-30 {
  width: 30px;
}

.padding-top-10 {
  padding-top: 10px;
}